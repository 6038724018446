@import 'styles/mixins';

.stickyHeader {
    animation: fadeInMove 1s cubic-bezier(0, 0, 0, 0.96) 0.5s forwards;
    opacity: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 32px;
    left: 0;
    right: 0;
    width: min(92%, 430px);
    margin: 0px auto;
    padding: 4px;
    border-radius: 30px;
    border: 0.25px solid rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 18px -2px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(255, 255, 255, 0.35) inset;
    backdrop-filter: blur(25px);

    .stickyHeaderButtons {
        display: flex;
        justify-content: center;
        align-items: center;

        .stickyHeaderButtonLogIn {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 24px;
            border: none;
            background: transparent;

            color: #181818;
            font-size: 13px;
            font-style: normal;
            font-weight: 510;
            line-height: 16px; /* 123.077% */
        }

        .stickyHeaderButtonGetStarted {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            border: none;
            // background: radial-gradient(151.68% 100% at 50% 0%, #233E66 0%, #000 100%), radial-gradient(151.68% 100% at 50% 0%, #FFF 0%, #FCFCFC 100%);
            background: radial-gradient(151.68% 100% at 50% 0%, #007aff 0%, #075a9e 100%);
            box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.12);
            color: #fff;
            font-size: 13px;
            font-weight: bold;
            line-height: 16px; /* 123.077% */
            transition: all 200s;

            &:hover {
                background: radial-gradient(151.68% 100% at 50% 0%, hsl(216, 49%, 34%) 0%, #000 100%),
                    radial-gradient(151.68% 100% at 50% 0%, #fff 0%, #fcfcfc 100%);
                box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .stickyHeaderIcons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .stickyHeaderDiscoIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
        }

        .stickyHeaderBaseIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            color: #181818;
            font-size: 13px;
            font-weight: 510;
            line-height: 16px; /* 123.077% */
            div {
                box-shadow: 0 0 5px 1px #00000022;
                border-radius: 6px;
            }
        }
    }

    @include mobile {
        top: 12px;
    }
}

.personalInfoForm {
    gap: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 358px;

    .personalInfoTitle {
        color: rgba(0, 0, 0, 0.5);
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 4px;
        font-weight: 510;
    }

    @include mobile {
        gap: 32px;
    }

    @include mobileS {
        width: 100%;
    }
}

.popupHeader {
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.waitlistSteps {
    .steps {
        gap: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .step {
            gap: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                fill: currentColor;
                width: 16px;
                height: 16px;
            }
        }
        .downloadButton {
            font-weight: 590;
            width: initial;
            padding: 8px 16px;
    
            &:not(:disabled) {
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
}

.downloads {
    gap: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .downloadsBaseIcon {
        padding: 16px;
        border-radius: 18px;
        background: rgba(92, 92, 92, 0.3);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
        height: 80px;
        width: 80px;
    }

    .downloadsApp {
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        color: #646464;
        font-size: 11px;
        font-weight: 510;
        line-height: 140%; /* 15.4px */
    }

    .downloadsSubtitle {
        color: rgba(0, 0, 0, 0.5);

        font-size: 13px;
        font-weight: 510;
        line-height: 16px; /* 123.077% */
    }

    .downloadsTitle {
        color: #000;
        font-size: 26px;
        font-weight: bold;
        line-height: 32px; /* 123.077% */
    }

    .downloadLinks {
        gap: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .downloadLinkPlatforms {
            gap: 18px;
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                fill: currentColor;
                width: 16px;
                height: 16px;
            }
        }

        .downloadLinksHint {
            text-align: center;
            color: #a3a3a3;
            font-family: Inter;
            font-size: 11px;
            font-weight: 500;
            line-height: 140%; /* 15.4px */
        }
    }

    .downloadButton {
        font-weight: 590;
        width: initial;

        &:not(:disabled) {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    @include mobile {
        gap: 24px;
    }
}

@keyframes fadeInMove {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    30% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.roundNumber {
    width: 38px;
    height: 38px;
    border: 1px solid #333333;
    border-radius: 50%;
    background-color: #E2E2E2;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
}