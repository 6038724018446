@import 'styles/mixins';

.landing {
    padding: 0px 32px;
    animation: opacityFadeIn 1s linear 0s forwards;
    background: radial-gradient(30.58% 14.93% at 50% 15%, #fff 0%, #fff0 100%),
        radial-gradient(55.51% 86.63% at 117.63% 77.39%, #0003 0%, #0000 100%),
        radial-gradient(56.18% 87.06% at -15.11% 78.74%, #0003 0%, #0000 100%),
        radial-gradient(72.91% 72.91% at 50% 27.09%, #fff0 0%, #ededed 100%);

    :global {
        .modal-old {
            border-radius: 16px;
            padding: 40px 64px;
            max-width: 520px;

            @include mobile {
                max-width: 440px;
                padding: 20px 32px;
            }
        }
    }

    @include mobile {
        padding: 0px 20px;
    }
}

.appLogoName {
    animation: fadeInMove 1s cubic-bezier(0, 0, 0, 0.96) 0.5s forwards;
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: center;

    margin-bottom: 10px;
    padding-top: 95px;
    opacity: 0;

    .appLogo {
        fill: linear-gradient(209deg, rgba(26, 241, 255, 0) 17.82%, rgba(5, 155, 239, 0) 95.87%),
            linear-gradient(162deg, #fff 12.28%, rgba(255, 255, 255, 0) 91.21%), #fff;
        stroke-width: 0.581px;
        stroke: #bdbdbd;
        filter: drop-shadow(0px 2.115891218185425px 4.23178243637085px rgba(0, 0, 0, 0.3))
            drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
    }
    .name {
        color: rgba(0, 0, 0, 0.5);
        font-size: 30.225px;
        font-weight: 590;

        @include mobile {
            font-size: 24px;
        }
    }

    @include mobile {
        margin-bottom: 8px;
        padding-top: 64px;
        gap: 12px;
    }
}

.heroTextContainer {
    margin-bottom: 36px;

    @include mobile {
        margin-bottom: 24px;

        height: 132px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include mobileS {
        height: 176px;
    }

    @include mobileXS {
        height: 220px;
    }
}

.heroText {
    animation: fadeInMove 1s cubic-bezier(0, 0, 0, 0.96) 0s forwards;

    color: #181818;
    text-align: center;
    font-size: 80px;
    font-weight: bold;
    line-height: 110%; /* 88px */
    opacity: 0;

    .activeUseCase {
        position: relative;
        // background: linear-gradient(#c0f0fc, #3385b5);
        background: var(--gradient);
        background-clip: text;
        -webkit-background-clip: text;
    }

    .useCaseName {
        -webkit-text-fill-color: transparent;
    }

    .useCaseEmoji {
        position: absolute;
        top: calc(32px * var(--scale, 1));
        right: calc(-36px - var(--offset, 0px));
        font-size: 50px;
        transform: rotate(-10deg) scale(var(--scale, 1));

        --stroke-pos: 2px;
        --stroke-neg: -2px;
        --stroke-color: rgb(255 255 255);
        text-shadow: var(--stroke-pos) 0 var(--stroke-color), var(--stroke-neg) 0 var(--stroke-color),
            0 var(--stroke-pos) var(--stroke-color), 0 var(--stroke-neg) var(--stroke-color),
            var(--stroke-pos) var(--stroke-pos) var(--stroke-color),
            var(--stroke-pos) var(--stroke-neg) var(--stroke-color),
            var(--stroke-neg) var(--stroke-pos) var(--stroke-color),
            var(--stroke-neg) var(--stroke-neg) var(--stroke-color);
    }

    @include mobile {
        font-size: 40px;

        .useCaseEmoji {
            --scale: 0.5;
            --base-offset: -72px;
        }
    }
}

.subtitle {
    animation: opacityFadeIn 1s linear 1.5s forwards;

    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    line-height: 120%; /* 30px */
    opacity: 0;
    margin-bottom: 32px;

    @include mobile {
        margin-bottom: 60px;
        font-size: 20px;
    }
}

.guide {
    animation: opacityFadeIn 1s linear 1.5s forwards;
    opacity: 0;

    margin-bottom: 100px;

    display: flex;
    align-items: center;
    gap: 8px;

    text-align: center;

    color: var(--text-secondary, rgba(0, 0, 0, 0.5));
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    justify-content: center;

    .kbd {
        display: flex;
        padding: 2px 4px;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        border-radius: 3px;
        background: var(--fill-black-5, #f5f5f5);
        box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
    }

    @include mobile {
        display: none;
    }
}

.roleButtons {
    max-width: 80%;
    margin: 0 auto 52px;

    animation: fadeInMoveUp 1s cubic-bezier(0, 0, 0, 0.96) 0.5s forwards;
    opacity: 0;

    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @include notMobile {
        justify-content: center;
    }

    @include mobile {
        overflow: scroll;
        scrollbar-width: none;
        flex-wrap: nowrap;
        max-width: 95%;
        padding: 0px calc(50% - (170px + 20px) / 2);
        mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.useCaseRole {
    text-transform: capitalize;

    display: flex;
    min-width: 170px;
    padding: 16px;
    align-items: center;
    justify-content: center;
    gap: 16px;

    border-radius: 8px;
    border: 0.5px solid #bfbfbf;
    background: var(--fill-group, #fafafa);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    color: var(--text-secondary, rgba(0, 0, 0, 0.5));
    font-feature-settings: 'clig' off, 'liga' off;

    /* Bold/Title 2 */
    font-size: 17px;
    font-style: normal;
    font-weight: 590;
    line-height: 22px; /* 129.412% */
    letter-spacing: -0.17px;

    &.active {
        background: var(--fill-white, #fff);
        color: var(--text-primary, rgba(0, 0, 0, 0.85));
    }

    position: relative;
    overflow: hidden;

    &.active.animate::after {
        content: '';
        width: 100%;
        height: 0;
        position: absolute;
        bottom: 0px;
        right: 0;
        background-color: #0000005d;

        animation: fill var(--duration) linear 0.5s forwards, height 0.5s ease-in forwards;
    }

    @keyframes fill {
        100% {
            width: 0%;
        }
    }

    @keyframes height {
        0% {
            height: 0px;
        }
        100% {
            height: 2px;
        }
    }
}

.previewImages {
    margin-bottom: 267px;

    .background {
        animation: fadeInMoveUp 1s cubic-bezier(0, 0, 0, 0.96) 0.5s forwards;
        opacity: 0;

        margin: 0px auto;
        padding: 67px 55px;
        width: 80%;
        border-radius: 30px;
        // background: center/cover no-repeat url('/images/landingPage/bg.png'), lightgray 50% / cover no-repeat;

        .backgroundImage {
            border-radius: 30px;            
        }

        .appImageContainer {
            opacity: 0;
            animation: fadeInMoveUp 1s cubic-bezier(0, 0, 0, 0.96) 0.5s forwards;

            position: relative;
            width: 100%;
            margin: 0px auto;
            max-width: 1190px;
            aspect-ratio: 1190/824;
            border-radius: 12px;
            filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));

            .phoneImage {
                opacity: 0;
                animation: fadeInMoveUp 1s cubic-bezier(0, 0, 0, 0.96) 0.5s forwards;

                position: absolute;
                top: 30%;
                left: 55%;
                width: 33%;
                max-width: 388px;
                aspect-ratio: 388/798;
                filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
            }
            
            .appImage {
                border-radius: 12px;
            }
        }


        @include mobile {
            margin: 0px auto;
            padding: 0px;
            border-radius: 30px;
            background: transparent;
        }

        @include mobileS {
            width: 95%;
        }
    }

    @include mobile {
        margin-bottom: 180px;
    }

    @include mobileS {
        margin-bottom: 160px;
    }
}

.footer {
    text-align: center;
    /* Bold/Title 2 */
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    padding-bottom: 5rem;

    .text {
        color: rgba(255, 255, 255, 0.5);
    }

    .link {
        color: #007aff;
    }

    .smallLink {
        font-size: 14px;
        color: #007aff;
        padding-right: 5px;
        padding-left: 5px;
    }

    @include mobile {
        padding-bottom: 2rem;
        font-size: 14px;
        line-height: 18px;
    }
}

.hide {
    width: 0px;
    height: 0px;
    padding: 0;
    margin: 0;
    z-index: 10000;
}

@keyframes fadeInMove {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    30% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInMoveUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    30% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes opacityFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes imageFadeIn {
    0% {
        transform: translateY(50px) scale(0.9);
    }
    100% {
        transform: translateY(0) scale(1);
    }
}

@keyframes deviceFadeIn {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    10% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
